let jsonConfig: DTO.GlobalConfiguration = {
  excelEngineDomain: '',
  validHierarchicalGroup: '',
  userPilotToken: '',
  redirectUrl: '',
  defaultTenant: '',
  defaultTimeout: '',
  idpConfiguration: '',
  apiGatewayApiVersion: '',
  apiGatewaySubscriptionKey: '',
  skipLoginFlow: '',
  issuers: [],
};

export const fetchJsonConfig = async () => {
  const url = `${window.location.protocol}//${window.location.host}/config.json`;

  await fetch(url)
    .then(response => response.text())
    .then(text => {
      try {
        jsonConfig = JSON.parse(text);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    });
};

export const getConfig = (): DTO.GlobalConfiguration => {
  const config: DTO.GlobalConfiguration = {
    excelEngineDomain:
      jsonConfig.excelEngineDomain === ''
        ? process.env.REACT_APP_EXCELENGINE_DOMAIN
        : jsonConfig.excelEngineDomain,

    validHierarchicalGroup:
      jsonConfig.validHierarchicalGroup === ''
        ? process.env.REACT_APP_VALID_HIERARCHICAL_GROUP
        : jsonConfig.validHierarchicalGroup,

    userPilotToken:
      jsonConfig.userPilotToken === ''
        ? process.env.REACT_APP_USERPILOT_APP_TOKEN
        : jsonConfig.userPilotToken,

    redirectUrl:
      jsonConfig.redirectUrl === ''
        ? process.env.REACT_APP_REDIRECT_URL
        : jsonConfig.redirectUrl,

    defaultTenant:
      jsonConfig.defaultTenant === ''
        ? process.env.REACT_APP_DEFAULT_TENANT
        : jsonConfig.defaultTenant,

    defaultTimeout:
      jsonConfig.defaultTimeout === ''
        ? process.env.REACT_APP_DEFAULT_TIMEOUT_IN_MINUTE
        : jsonConfig.defaultTimeout,

    idpConfiguration:
      jsonConfig.idpConfiguration === ''
        ? process.env.REACT_APP_IDP_CONFIGURATION_FILE
        : jsonConfig.idpConfiguration,

    apiGatewayApiVersion:
      jsonConfig.apiGatewayApiVersion === ''
        ? process.env.REACT_APP_APIGATEWAY_API_VERSION
        : jsonConfig.apiGatewayApiVersion,

    apiGatewaySubscriptionKey:
      jsonConfig.apiGatewaySubscriptionKey === ''
        ? process.env.REACT_APP_APIGATEWAY_SUBSCRIPTION_KEY
        : jsonConfig.apiGatewaySubscriptionKey,

    skipLoginFlow:
      jsonConfig.skipLoginFlow === ''
        ? process.env.REACT_APP_SKIP_LOGIN_FLOW
        : jsonConfig.skipLoginFlow,

    issuers: jsonConfig.issuers,
  };

  return config;
};
