import React, { NamedExoticComponent, memo, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

const StyledImg = styled.img<{ width?: string; height?: string }>`
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  cursor: pointer;
`;

interface LogoProps {
  type?:
    | 'OnWhite'
    | 'OnWhiteAnimation'
    | 'OnWhiteWithoutText'
    | 'OnColoured'
    | 'OnColouredWithoutText'
    | '';
  width?: string;
  height?: string;
}

const Logo: NamedExoticComponent<LogoProps> = memo(
  ({ type, width, height }) => {
    const theme = useContext(ThemeContext);

    const logoSvgPath = type
      ? theme[`logo${type}`].substring(1)
      : theme['logo'];

    return (
      <StyledImg
        src={logoSvgPath}
        width={width}
        data-testid="logo"
        height={height}
      />
    );
  }
);

export { Logo };
