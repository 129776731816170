import { AppAction } from '../actions/types';
import { BackgroundJobsConstants, FileManagerConstants } from '../constants';
import { arrayRemoveFirst, arrayReplaceFirst } from '../helpers';

const initialRootState: STATES.FileManagerRootState = {
  selectedFolderPathOnFiles: '',
  waitingSelectedFolderDetails: null,
  isLoadingFileDetails: false,
  selectedFileId: null,
  selectedFolderDetails: null,
  isUpdatingFolder: false,
  selectedFile: null,
  isUpdatingFile: false,
  filesAndFolders: {
    files: [],
    folders: [],
  },
  filesRefreshTrigger: 0,
  isLoadingFolders: false,
  sortFilesAndFolder: false,
  sharingFolderPath: '',
  foldersInParent: [],
  selectedParentFolder: '',
  foldersInAction: {},
  subFoldersInAction: {},
  isLoadingFolderDetails: false,
  selectedFolderId: null,
  isCreatingFolder: false,
  isErrorFetchingFolder: false,
};

export const initialState: STATES.FileManagerState = {
  root: { ...initialRootState },
};

export const fileManager = (
  state = initialState,
  action: AppAction
): STATES.FileManagerState => {
  switch (action.type) {
    case FileManagerConstants.GET_FOLDER_REQUEST: {
      const { folderPath } = action.payload;

      return {
        ...state,
        root: {
          ...state.root,
          selectedParentFolder: folderPath,
          isLoadingFolders: true,
          sortFilesAndFolder: false,
          foldersInParent: [],
          foldersInAction: {},
          isErrorFetchingFolder: false,
        },
      };
    }
    case FileManagerConstants.GET_FOLDER_SUCCESS: {
      const { folders, files } = action.payload;

      let rootState = state.root;

      rootState = {
        ...rootState,
        isLoadingFolders: false,
        foldersInParent: folders,
        filesAndFolders: { files, folders },
        sortFilesAndFolder: false,
        isErrorFetchingFolder: false,
      };

      return {
        ...state,
        root: rootState,
      };
    }
    case FileManagerConstants.GET_FOLDER_FAILURE: {
      const rootState = state.root;

      return {
        ...state,
        root: {
          ...rootState,
          isLoadingFolders: false,
          isErrorFetchingFolder: true,
        },
      };
    }
    case FileManagerConstants.ADD_FOLDER: {
      const { timestamp } = action.payload;

      const rootState = state.root;

      return {
        ...state,
        root: {
          ...state.root,
          foldersInParent: [
            {
              id: timestamp,
              name: timestamp,
              path: rootState.selectedParentFolder,
              metadata: {},
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
            },
            ...state.root.foldersInParent,
          ],
          foldersInAction: {
            ...rootState.foldersInAction,
            [timestamp]: 'new',
          },
        },
      };
    }
    case FileManagerConstants.SAVE_NEW_FOLDER_REQUEST: {
      const { folderTimestamp } = action.payload;

      return {
        ...state,
        root: {
          ...state.root,
          isCreatingFolder: true,
          foldersInAction: {
            ...state.root.foldersInAction,
            [folderTimestamp]: 'saving',
          },
        },
      };
    }
    case FileManagerConstants.SAVE_NEW_FOLDER_SUCCESS: {
      const { folderTimestamp, folder } = action.payload;

      const rootState = state.root;

      const {
        [folderTimestamp]: _,
        ...foldersInAction
      } = rootState.foldersInAction;

      const foldersInParent = arrayReplaceFirst(
        rootState.foldersInParent,
        f => f.id === folderTimestamp,
        folder
      );

      return {
        ...state,
        root: {
          ...state.root,
          foldersInAction,
          foldersInParent,
          isCreatingFolder: false,
        },
      };
    }
    case FileManagerConstants.SAVE_NEW_FOLDER_FAILURE: {
      return {
        ...state,
        root: {
          ...state.root,
          isCreatingFolder: false,
        },
      };
    }
    case FileManagerConstants.CANCEL_NEW_FOLDER: {
      const { folderName } = action.payload;
      const rootState = state.root;
      const { [folderName]: _, ...foldersInAction } = rootState.foldersInAction;
      let foldersInParent = rootState.foldersInParent;

      if (
        rootState.foldersInAction[folderName] &&
        rootState.foldersInAction[folderName] === 'new'
      ) {
        foldersInParent = arrayRemoveFirst(
          foldersInParent,
          folder => folder.id === folderName
        );
      }

      return {
        ...state,
        root: {
          ...state.root,
          foldersInParent,
          foldersInAction,
        },
      };
    }

    case FileManagerConstants.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        root: {
          ...state.root,
          filesRefreshTrigger: state.root.filesRefreshTrigger + 1,
          selectedFolderPathOnFiles: '',
        },
      };
    }
    case FileManagerConstants.UPDATE_FOLDER_SUCCESS: {
      return {
        ...state,
        root: {
          ...state.root,
        },
      };
    }
    case FileManagerConstants.DELETE_FOLDER_REQUEST: {
      const { id } = action.payload;

      return {
        ...state,
        root: {
          ...state.root,
          foldersInAction: {
            ...state.root.foldersInAction,
            [id]: 'deleting',
          },
        },
      };
    }
    case FileManagerConstants.DELETE_FOLDER_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        root: {
          ...state.root,
          filesAndFolders: {
            ...state.root.filesAndFolders,
            folders: arrayRemoveFirst(
              state.root.filesAndFolders.folders,
              folder => folder.id === id
            ),
          },
        },
      };
    }
    case FileManagerConstants.DELETE_FOLDER_FAILURE: {
      return {
        ...state,
      };
    }
    case FileManagerConstants.GET_FILE_DETAILS_REQUEST: {
      const { fileId } = action.payload;

      return {
        ...state,
        root: {
          ...state.root,
          selectedFileId: fileId,
          isLoadingFileDetails: true,
          selectedFile: null,
        },
      };
    }
    case FileManagerConstants.GET_FILE_DETAILS_SUCCESS: {
      const { fileId, file } = action.payload;

      return {
        ...state,
        root: {
          ...state.root,
          selectedFileId: fileId,
          isLoadingFileDetails: false,
          selectedFile: file,
        },
      };
    }
    case FileManagerConstants.GET_FILE_DETAILS_FAILURE: {
      return {
        ...state,
        root: {
          ...state.root,
          isLoadingFolderDetails: false,
        },
      };
    }
    case FileManagerConstants.GET_FOLDER_DETAILS_REQUEST: {
      const { id } = action.payload;

      return {
        ...state,
        root: {
          ...state.root,
          selectedFolderId: id,
          isLoadingFolderDetails: true,
          selectedFolderDetails: null,
        },
      };
    }
    case FileManagerConstants.GET_FOLDER_DETAILS_SUCCESS: {
      const { id, folder } = action.payload;

      return {
        ...state,
        root: {
          ...state.root,
          selectedFolderId: id,
          isLoadingFolderDetails: false,
          selectedFolderDetails: folder,
        },
      };
    }
    case FileManagerConstants.GET_FOLDER_DETAILS_FAILURE: {
      return {
        ...state,
        root: {
          ...state.root,
          isLoadingFolderDetails: false,
        },
      };
    }
    case FileManagerConstants.DELETE_FILE_SUCCESS: {
      const { fileId } = action.payload;

      return {
        ...state,
        root: {
          ...state.root,
          filesAndFolders: {
            ...state.root.filesAndFolders,
            files: arrayRemoveFirst(
              state.root.filesAndFolders.files,
              file => file.id === fileId
            ),
          },
        },
      };
    }
    case FileManagerConstants.UPDATE_FILE_REQUEST: {
      return {
        ...state,
        root: {
          ...state.root,
          isUpdatingFile: true,
        },
      };
    }
    case FileManagerConstants.UPDATE_FILE_SUCCESS: {
      return {
        ...state,
        root: {
          ...state.root,
          filesRefreshTrigger: state.root.filesRefreshTrigger + 1,
          isUpdatingFile: false,
          selectedFolderPathOnFiles: '',
        },
      };
    }
    case FileManagerConstants.UPDATE_FILE_FAILURE: {
      return {
        ...state,
        root: {
          ...state.root,
          isUpdatingFile: false,
        },
      };
    }

    case BackgroundJobsConstants.GET_BGJOBS_SUCCESS: {
      const { reloadProductFolderList } = action.payload;

      if (!reloadProductFolderList) {
        return state;
      }

      return {
        ...state,
        root: {
          ...state.root,
          selectedFolderPathOnFiles: '',
          selectedParentFolder: '',
        },
      };
    }

    default:
      return state;
  }
};
