export const GET_MASTER_REFS_REQUEST = 'GET_MASTER_REFS_REQUEST';
export const GET_MASTER_REFS_SUCCESS = 'GET_MASTER_REFS_SUCCESS';
export const GET_MASTER_REFS_FAILURE = 'GET_MASTER_REFS_FAILURE';

export const GET_MASTER_REF_VERSIONS_REQUEST =
  'GET_MASTER_REF_VERSIONS_REQUEST';
export const GET_MASTER_REF_VERSIONS_SUCCESS =
  'GET_MASTER_REF_VERSIONS_SUCCESS';
export const GET_MASTER_REF_VERSIONS_FAILURE =
  'GET_MASTER_REF_VERSIONS_FAILURE';

export const GET_MASTER_API_REQUEST = 'GET_MASTER_API_REQUEST';
export const GET_MASTER_API_SUCCESS = 'GET_MASTER_API_SUCCESS';
export const GET_MASTER_API_FAILURE = 'GET_MASTER_API_FAILURE';

export const GET_MASTER_API_VERSIONS_REQUEST =
  'GET_MASTER_API_VERSIONS_REQUEST';
export const GET_MASTER_API_VERSIONS_SUCCESS =
  'GET_MASTER_API_VERSIONS_SUCCESS';
export const GET_MASTER_API_VERSIONS_FAILURE =
  'GET_MASTER_API_VERSIONS_FAILURE';

export const MASTER_REF_UPLOAD_START = 'MASTER_REF_UPLOAD_START';
export const MASTER_REF_UPLOAD_PROGRESS = 'MASTER_REF_UPLOAD_PROGRESS';
export const MASTER_REF_UPLOAD_XHR_REF = 'MASTER_REF_UPLOAD_XHR_REF';
export const MASTER_REF_UPLOAD_SUCCESS = 'MASTER_REF_UPLOAD_SUCCESS';
export const MASTER_REF_UPLOAD_ERROR = 'MASTER_REF_UPLOAD_ERROR';
export const MASTER_REF_UPLOAD_RESET = 'MASTER_REF_UPLOAD_RESET';
export const MASTER_REF_SYNC_RESET = 'MASTER_REF_SYNC_RESET';

export const MASTER_REF_PUBLISH_START = 'MASTER_REF_PUBLISH_START';
export const MASTER_REF_PUBLISH_SUCCESS = 'MASTER_REF_PUBLISH_SUCCESS';
export const MASTER_REF_PUBLISH_FAILURE = 'MASTER_REF_PUBLISH_FAILURE';

export const SELECT_MASTER_REF = 'SELECT_MASTER_REF';

export const DELETE_MASTER_REF_REQUEST = 'DELETE_MASTER_REF_REQUEST';
export const DELETE_MASTER_REF_SUCCESS = 'DELETE_MASTER_REF_SUCCESS';
export const DELETE_MASTER_REF_FAILURE = 'DELETE_MASTER_REF_FAILURE';

export const MASTER_REF_SYNC_REQUEST = 'MASTER_REF_SYNC_REQUEST';
export const MASTER_REF_SYNC_SUCCESS = 'MASTER_REF_SYNC_SUCCESS';
export const MASTER_REF_SYNC_FAILURE = 'MASTER_REF_SYNC_FAILURE';

export const MASTER_REF_SYNC_ALL_REQUEST = 'MASTER_REF_SYNC_ALL_REQUEST';
export const MASTER_REF_SYNC_ALL_SUCCESS = 'MASTER_REF_SYNC_ALL_SUCCESS';
export const MASTER_REF_SYNC_ALL_FAILURE = 'MASTER_REF_SYNC_ALL_FAILURE';

export const UPDATE_MASTER_REF_REQUEST = 'UPDATE_MASTER_REF_REQUEST';
export const UPDATE_MASTER_REF_SUCCESS = 'UPDATE_MASTER_REF_SUCCESS';
export const UPDATE_MASTER_REF_FAILURE = 'UPDATE_MASTER_REF_FAILURE';

export const GET_MASTER_REF_DETAIL_REQUEST = 'GET_MASTER_REF_DETAIL_REQUEST';
export const GET_MASTER_REF_DETAIL_SUCCESS = 'GET_MASTER_REF_DETAIL_SUCCESS';
export const GET_MASTER_REF_DETAIL_FAILURE = 'GET_MASTER_REF_DETAIL_FAILURE';

export const GET_DOWNSTREAM_ENGINE_REQUEST = 'GET_DOWNSTREAM_ENGINE_REQUEST';
export const GET_DOWNSTREAM_ENGINE_SUCCESS = 'GET_DOWNSTREAM_ENGINE_SUCCESS';
export const GET_DOWNSTREAM_ENGINE_FAILURE = 'GET_DOWNSTREAM_ENGINE_FAILURE';

export const READ_MASTER_REFERENCE_RANGE_REQUEST =
  'READ_MASTER_REFERENCE_RANGE_REQUEST';
export const READ_MASTER_REFERENCE_RANGE_SUCCESS =
  'READ_MASTER_REFERENCE_RANGE_SUCCESS';
export const READ_MASTER_REFERENCE_RANGE_FAILURE =
  'READ_MASTER_REFERENCE_RANGE_FAILURE';

export const WRITE_MASTER_REFERENCE_RANGE_REQUEST =
  'WRITE_MASTER_REFERENCE_RANGE_REQUEST';
export const WRITE_MASTER_REFERENCE_RANGE_SUCCESS =
  'WRITE_MASTER_REFERENCE_RANGE_SUCCESS';
export const WRITE_MASTER_REFERENCE_RANGE_FAILURE =
  'WRITE_MASTER_REFERENCE_RANGE_FAILURE';

export const GET_PRODUCT_TURORIALS_REQUEST = 'GET_PRODUCT_TURORIALS_REQUEST';
export const GET_PRODUCT_TURORIALS_SUCCESS = 'GET_PRODUCT_TURORIALS_SUCCESS';
export const GET_PRODUCT_TURORIALS_FAILURE = 'GET_PRODUCT_TURORIALS_FAILURE';

export const VALIDATE_PRODUCT_TURORIALS_REQUEST =
  'VALIDATE_PRODUCT_TURORIALS_REQUEST';
export const VALIDATE_PRODUCT_TURORIALS_SUCCESS =
  'VALIDATE_PRODUCT_TURORIALS_SUCCESS';
export const VALIDATE_PRODUCT_TURORIALS_FAILURE =
  'VALIDATE_PRODUCT_TURORIALS_FAILURE';

export const CLEAR_MASTER_REFERENCE_RANGE = 'CLEAR_MASTER_REFERENCE_RANGE';

export const MATCH_STATUS = 'MATCH';
export const MISMATCH_STATUS = 'MISMATCH';
