import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { SystemActions } from '../actions';

const lookupDataSelector = ({
  system: { isLoadingLookupData, lookupData },
}: STATES.AppState) => ({
  isLoadingLookupData,
  lookupData,
});

const mapResponseToLookUpDataEntry = (
  data: DTO.LookupData,
  key: keyof DTO.LookupData
): DTO.LookUpDataEntry[] => {
  const rawDataForKey = data[key];

  if (Array.isArray(rawDataForKey)) {
    const lookupDataEntry: DTO.LookUpDataEntry[] = [];
    rawDataForKey.forEach(({ Key, Value }) => {
      lookupDataEntry.push({
        key: Key,
        value: Value,
      });
    });
    return lookupDataEntry;
  }

  return [];
};

const mapResponseToLookUpDataEntryForArray = (
  data: DTO.LookupData,
  key: keyof DTO.LookupData
): DTO.LookUpDataEntryForArray[] => {
  const rawDataForKey = data[key];
  if (Array.isArray(rawDataForKey)) {
    const lookupDataEntryForArray: DTO.LookUpDataEntryForArray[] = [];
    rawDataForKey.forEach(({ Key, Value }) => {
      lookupDataEntryForArray.push({
        key: Key,
        value: Value,
      });
    });
    return lookupDataEntryForArray;
  }

  return [];
};

const mapProductCategoires = (data: DTO.LookupData): DTO.ProductCategory[] => {
  const rawDataForKey = data['Metadata.ProductCategories'];
  const productCategories: DTO.ProductCategory[] = [];
  rawDataForKey.forEach(({ Key, Value, Icon }) => {
    productCategories.push({
      key: Key,
      value: Value,
      icon: Icon,
    });
  });
  return productCategories;
};

const mapCategoryIcons = (data: DTO.LookupData): object => {
  return data['Metadata.CategoryIcons'];
};

const useLookupData = () => {
  const dispatch = useDispatch();
  const { isLoadingLookupData, lookupData } = useSelector(lookupDataSelector);

  useEffect(() => {
    if (!lookupData) {
      dispatch(SystemActions.getLookupData());
    }
    // Only run on didMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const mappedLookupData = useMemo<{
    coverImages: DTO.LookUpDataEntry[];
    productStatuses: DTO.LookUpDataEntry[];
    productCategories: DTO.ProductCategory[];
    executePurposes: DTO.LookUpDataEntry[];
    releaseNote: string;
    releaseVersion: number;
    fileTypes: DTO.LookUpDataEntryForArray[];
    categoryIcons: object | undefined;
    gitBookUrl: string;
  }>(() => {
    if (!lookupData) {
      return {
        coverImages: [],
        productStatuses: [],
        productCategories: [],
        executePurposes: [],
        releaseNote: '',
        releaseVersion: 0,
        fileTypes: [],
        categoryIcons: undefined,
        gitBookUrl: '',
      };
    }

    const coverImages = mapResponseToLookUpDataEntry(
      lookupData,
      'Metadata.CoverImages'
    );

    coverImages.forEach(coverImage => {
      coverImage.value = coverImage.value.substr(1);
    });

    const productCategories = mapProductCategoires(lookupData);
    const productStatuses = mapResponseToLookUpDataEntry(
      lookupData,
      'Metadata.ProductStatus'
    );
    const executePurposes = mapResponseToLookUpDataEntry(
      lookupData,
      'Metadata.ExecutePurpose'
    );
    const releaseNote = lookupData['Metadata.ReleaseNote'];
    const releaseVersion = lookupData['Metadata.ReleaseVersion'];
    const fileTypes = mapResponseToLookUpDataEntryForArray(
      lookupData,
      'Metadata.FileTypes'
    );
    const categoryIcons = mapCategoryIcons(lookupData);
    const gitBookUrl = lookupData['Metadata.GitBookUrl'];

    return {
      coverImages,
      productCategories,
      productStatuses,
      executePurposes,
      releaseNote,
      releaseVersion,
      fileTypes,
      categoryIcons,
      gitBookUrl,
    };
  }, [lookupData]);

  return {
    ...mappedLookupData,
    lookupData,
    isLoadingLookupData,
  };
};

export { useLookupData };
