import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import queryString from 'query-string';

interface PrivateRouteProps extends RouteProps {
  loggedIn: boolean;
  isLoggingOut?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  render,
  loggedIn,
  isLoggingOut,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (loggedIn) {
        if (render) {
          return render(props);
        }

        if (Component) {
          return <Component {...props} />;
        }

        return <Redirect to="/" />;
      }

      let redirectUrl;
      let tenant;
      if (window.location && window.location.search) {
        const qss = queryString.parse(window.location.search);
        redirectUrl = qss.return_url;
        tenant = qss.tenant as string;
        tenant && localStorage.setItem('Tenant', tenant);
      }

      if (!redirectUrl) {
        redirectUrl = window.location.href.replace(
          window.location.origin + process.env.REACT_APP_PUBLIC_URL,
          ''
        );
        redirectUrl = redirectUrl === '/' ? '' : redirectUrl;
      }

      return (
        <Redirect
          to={`/login/?${tenant ? `tenant=${tenant}&` : ''}${
            redirectUrl ? `return_url=${redirectUrl.replace(/ /g, '@!@')}&` : ''
          }`}
        />
      );
    }}
  />
);

export { PrivateRoute };
