import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';

const getDictionaryData = (request: DTO.GetDataDictionaryRequest) => {
  const { sortBy = '', sort = 'desc', searchText = '' } = request;

  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/datadictionary/get?searchText=${searchText}&sort=${
    sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : ''
  }`;
  return makeRequest<DTO.GetDataDictionaryResponse>('GET', url);
};

const downloadDataDictionary = (id_Token: string) => {
  return `${
    getConfig().excelEngineDomain
  }/api/v1/datadictionary/download?token=${id_Token}`;
};

const uploadDataDictionary = (
  file: File,
  onUploadProgress: (percent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/datadictionary/upload`;

  const formData = new FormData();

  formData.append('file', file);

  return uploadRequest<{ data: DTO.ValidateProductZipResponse }>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

export const DataDictionaryService = {
  getDictionaryData,
  downloadDataDictionary,
  uploadDataDictionary,
};
