import React, { useRef, useState, FC, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import moment from 'moment';
import { Modal } from 'antd';
import { TimerConstants } from '../../constants';
import { getConfig } from '../../helpers';

type SessionTimeoutModalProps = {
  onTimeout: () => void;
};

const formatDuration = (duration: number) =>
  moment.utc(duration).format('m:ss');

const SessionTimeoutModal: FC<SessionTimeoutModalProps> = ({ onTimeout }) => {
  const idleTimerRef = useRef<IdleTimer>(null);
  const modalRef = useRef<ReturnType<typeof Modal.confirm>>();
  const countdownInterval = useRef<number>();
  const countdownTimeout = useRef<number>();
  const [showingPopup, setShowingPopup] = useState<boolean>(false);
  const [countdownTime, setCountdownTime] = useState<number>(
    TimerConstants.MODAL_COUNTDOWN_TIME
  );

  const config = getConfig();
  const modalTimeout =
    ((config.defaultTimeout === '' ? 15 : config.defaultTimeout) as number) *
      60 *
      1000 -
    TimerConstants.MODAL_COUNTDOWN_TIME;

  useEffect(() => {
    if (showingPopup) {
      modalRef.current &&
        modalRef.current.update({
          title: `The session will be automatically expired after ${formatDuration(
            countdownTime
          )}.`,
        });
    } else if (modalRef.current) {
      clearInterval(countdownInterval.current);
      clearTimeout(countdownTimeout.current);

      modalRef.current.destroy();
    }
  }, [showingPopup, countdownTime]);

  const onIdle = () => {
    if (showingPopup) {
      return;
    }

    idleTimerRef.current && idleTimerRef.current.pause();

    const countdownDuration = TimerConstants.MODAL_COUNTDOWN_TIME;

    setCountdownTime(countdownDuration);
    setShowingPopup(true);

    countdownInterval.current = setInterval(() => {
      setCountdownTime(prev => prev - 1000);
    }, 1000);

    modalRef.current = Modal.confirm({
      width: 700,
      title: `The session will be automatically expired after ${formatDuration(
        countdownDuration
      )}.`,
      icon: 'exclamation-circle',
      content: 'You can keep logged in by clicking the button below.',
      okText: 'Stay Logged In',
      cancelText: 'Logout',
      onOk: () => {
        idleTimerRef.current && idleTimerRef.current.reset();
        setShowingPopup(false);
      },
      onCancel: () => {
        // logout
        setShowingPopup(false);
        onTimeout();
      },
    });

    countdownTimeout.current = setTimeout(() => {
      // logout
      setShowingPopup(false);
      onTimeout();
    }, countdownDuration);
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        startOnMount
        events={[
          // 'mousemove', // disabled due to Safari triggers mousemove event when modal disappear
          'keydown',
          'wheel',
          'DOMMouseScroll',
          'mouseWheel',
          'mousedown',
          'touchstart',
          'touchmove',
          'MSPointerDown',
          'MSPointerMove',
        ]}
        onIdle={onIdle}
        debounce={500}
        timeout={modalTimeout}
      />
    </>
  );
};

export { SessionTimeoutModal };
