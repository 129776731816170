import aiadevidpdata from '../assets/aia.dev.idp.configuration.json';
import aiaproductionidpdata from '../assets/aia.production.idp.configuration.json';
import aiastagingidpdata from '../assets/aia.staging.idp.configuration.json';
import bolttechdevidpdata from '../assets/bolttech.dev.idp.configuration.json';
import chubbthidpdata from '../assets/chubb-th.idp.configuration.json';
import chubbthstgidpdata from '../assets/chubb-th.stg.idp.configuration.json';
import chubbidpdata from '../assets/chubb.idp.configuration.json';
import chubbprodidpdata from '../assets/chubb.prod.idp.configuration.json';
import chubbstgidpdata from '../assets/chubb.stg.idp.configuration.json';
import devidpdata from '../assets/dev.idp.configuration.json';
import pobsitidpdata from '../assets/pob.sit.idp.configuration.json';
import scblidpdata from '../assets/scbl.dev.idp.configuration.json';
import scblprodidpdata from '../assets/scbl.prod.idp.configuration.json';
import scblsitidpdata from '../assets/scbl.sit.idp.configuration.json';
import scblstgidpdata from '../assets/scbl.stg.idp.configuration.json';
import scbluatidpdata from '../assets/scbl.uat.idp.configuration.json';
import stgidpdata from '../assets/stg.idp.configuration.json';
import { getConfig } from '../helpers';
import makeRequest from '../helpers/make-request';

const createKeyCloakAnonymousUser = (
  anonymousUser: DTO.KeyCloakAnonymousUser
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/userlogin/keycloak/CreateAnonymousUser`;
  return makeRequest<{
    data: string;
  }>('POST', url, anonymousUser);
};

const sendOTPToAnonymousUser = (anonymousUserId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/userlogin/keycloak/SendOTPToAnonymousUser/${anonymousUserId}`;
  return makeRequest('POST', url);
};

const generateAnonymouseUserToken = (
  anonymousUserId: string,
  linkId: string,
  code: string,
  client: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/userlogin/keycloak/GenerateAnonymouseUserToken/${anonymousUserId}/${linkId}/${code}/${client}`;
  return makeRequest<{ data: { targetUrl: string; access_token: string } }>(
    'POST',
    url
  );
};

const exchangeToken = (
  code: string,
  redirectUrl: string,
  codeVerifier?: string | null
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/userlogin/GenerateToken?code=${code}&redirectUrl=${redirectUrl}&codeVerifier=${codeVerifier}`;
  return makeRequest<{
    data: {
      access_token: string;
      refresh_token: string;
      username: string;
    };
  }>('GET', url);
};

const getIssuerConfiguration = async (
  issuer?: string
): Promise<DTO.IssuerConfiguration | null> => {
  let data: DTO.IssuerConfiguration[] = devidpdata.issuers;
  const config = getConfig();
  const idpConfigFile = config.idpConfiguration;
  if (config?.issuers && config?.issuers?.length > 0) {
    data = config?.issuers;
  } else if (idpConfigFile === 'chubb.idp.configuration.json') {
    data = chubbidpdata?.issuers;
  } else if (idpConfigFile === 'scbl.dev.idp.configuration.json') {
    data = scblidpdata?.issuers;
  } else if (idpConfigFile === 'chubb.stg.idp.configuration.json') {
    data = chubbstgidpdata?.issuers;
  } else if (idpConfigFile === 'bolttech.dev.idp.configuration.json') {
    data = bolttechdevidpdata?.issuers;
  } else if (idpConfigFile === 'chubb.prod.idp.configuration.json') {
    data = chubbprodidpdata?.issuers;
  } else if (idpConfigFile === 'chubb-th.idp.configuration.json') {
    data = chubbthidpdata?.issuers;
  } else if (idpConfigFile === 'chubb-th.stg.idp.configuration.json') {
    data = chubbthstgidpdata?.issuers;
  } else if (idpConfigFile === 'stg.idp.configuration.json') {
    data = stgidpdata?.issuers;
  } else if (idpConfigFile === 'pob.sit.idp.configuration.json') {
    data = pobsitidpdata?.issuers;
  } else if (idpConfigFile === 'aia.dev.idp.configuration.json') {
    data = aiadevidpdata?.issuers;
  } else if (idpConfigFile === 'aia.staging.idp.configuration.json') {
    data = aiastagingidpdata?.issuers;
  } else if (idpConfigFile === 'aia.production.idp.configuration.json') {
    data = aiaproductionidpdata?.issuers;
  } else if (idpConfigFile === 'scbl.sit.idp.configuration.json') {
    data = scblsitidpdata?.issuers;
  } else if (idpConfigFile === 'scbl.uat.idp.configuration.json') {
    data = scbluatidpdata?.issuers;
  } else if (idpConfigFile === 'scbl.stg.idp.configuration.json') {
    data = scblstgidpdata?.issuers;
  } else if (idpConfigFile === 'scbl.prod.idp.configuration.json') {
    data = scblprodidpdata?.issuers;
  }

  if (data?.length > 0) {
    const tenant = localStorage.getItem('Tenant') || getConfig().defaultTenant;
    let issuerConfig: DTO.IssuerConfiguration | null = null;
    const issuerConfigTemp = data.find(
      s =>
        (issuer && s.issuer.toLowerCase() === issuer.toLowerCase()) ||
        (!issuer && tenant && s.tenant.toLowerCase() === tenant.toLowerCase())
    );

    if (issuerConfigTemp) {
      issuerConfig = issuerConfigTemp;
    }

    if (issuerConfig) {
      if (issuerConfig.verifierType === 'oidc') {
        let openIdConfigStr = localStorage.getItem(issuerConfig.verifierUri);
        if (!openIdConfigStr) {
          const response = await makeRequest<DTO.OpenIdConfiguarations>(
            'GET',
            issuerConfig.verifierUri,
            null,
            'application/json',
            null,
            null,
            false,
            true
          );

          if (response && response.payload && response.payload.issuer) {
            openIdConfigStr = JSON.stringify(response.payload);
            localStorage.setItem(issuerConfig.verifierUri, openIdConfigStr);
          }
        }

        if (openIdConfigStr) {
          const openIdConfig = JSON.parse(openIdConfigStr);
          issuerConfig.tokenUri = openIdConfig.token_endpoint;
          issuerConfig.authUri = openIdConfig.authorization_endpoint;
          issuerConfig.logoutUri = openIdConfig.end_session_endpoint;
        }
      }

      return issuerConfig;
    }
  }

  return null;
};

const getLogoutUrl = (
  issuerConfig: DTO.IssuerConfiguration | null,
  returnUrl?: string
) => {
  if (issuerConfig && issuerConfig.logoutUri) {
    const tenant = localStorage.getItem('Tenant') || getConfig().defaultTenant;
    if (issuerConfig.authType === 'AD') {
      return `${issuerConfig.logoutUri}?redirectUrl=${window.location.origin +
        process.env.REACT_APP_PUBLIC_URL}`;
    }
    return `${issuerConfig.logoutUri}?redirect_uri=${window.location.origin +
      process.env.REACT_APP_PUBLIC_URL}?${
      tenant ? `tenant=${tenant}&` : ''
    }return_url=${encodeURIComponent(
      `${returnUrl ||
        window.location.href
          .replace(
            window.location.origin + process.env.REACT_APP_PUBLIC_URL,
            ''
          )
          .replace(/ /g, '@!@')}`
    )}`;
  }
  return undefined;
};

export const UserService = {
  exchangeToken,
  getLogoutUrl,
  createKeyCloakAnonymousUser,
  sendOTPToAnonymousUser,
  generateAnonymouseUserToken,
  getIssuerConfiguration,
};
